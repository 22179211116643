<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <section>
            <table border="0" width="100%" height="150px">
                    <tr>
                        <td width="50%" height="400px" align="center" >
                            <img alt="Vue sm" src="./../assets/cm.jpg"> 
                        </td>

                        <td width="50%" height="400px"  align="center" >

                            <h2> Citrus and Mint Soap </h2>

                            <p> Citrus and Mint Soap combines the refreshing and invigorating scents 
                            of citrus fruits and mint, offering a revitalizing experience for the skin. 
                            Citrus oils such as lemon, orange, or grapefruit are rich in antioxidants,
                            which help protect the skin from free radical damage.  Mint oil provides a 
                            cooling sensation on the skin, which can help soothe irritation and inflammation.
                            It has natural antibacterial properties that can cleanse and purify the skin, 
                            making it beneficial for acne-prone skin.</p>

                            <p> Citrus and Mint Soap blends the revitalizing scents of citrus fruits and 
                            mint oils, offering a refreshing and cleansing experience for your skin. 
                            Whether you seek an energizing start to your day or a cooling cleanse after 
                            outdoor activities, this soap provides a natural and invigorating solution. 
                            Embrace the uplifting aroma and beneficial properties of citrus and mint for 
                            a rejuvenated and healthier-looking complexion.</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                        
                        </td>
                        <td align="center">
                            <router-link to ="OrderPage"> <button class="but"> Order Now </button> </router-link>
                        </td>
                    </tr>
			</table>
            </section>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"
export default {
    components:{
        HeaderPage, 
        FooterPage
        
    }
}
</script>
   
<style scoped>

    #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 300px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    } 
      
</style>