<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>
   
        <div id="content">
            <div>
                <h1> Milo Soap Serminar</h1>

                <P> Welcome to the wonderful world of soap making! Here, we celebrate the art
                of crafting beautiful, natural soaps that nourish your skin and delight your senses.Soap 
                making is a fun and creative hobby that allows you to craft your own custom bars at home. 
                The process, called saponification, involves combining fats or oils with an alkali, 
                typically lye, to create the soap.Soap making offers a lot of room for customization. You
                can add essential oils for fragrance, colorants for vibrant bars, or even include 
                botanicals like oatmeal or coffee grounds for exfoliation</P>

                <p> This seminar is perfect for anyone with a passion for creating handcrafted products
                and a desire to learn a rewarding new skill.  Whether you're a complete beginner curious 
                about the magic of soap making or a hobbyist eager to refine your techniques, we have 
                something for everyone.Our seminar goes beyond the fundamentals. We'll delve into advanced
                techniques like: Cold Process vs. Hot Process, Superfatting, Embeds & Swirls. </p>
            </div>

            <div>
                <h2> Here Are Some Home Made Soaps </h2>

                <ul>
					<li> Oatmeal Lavender Dreamscape Soap </li>
					<img alt="Vue sm" src="./../assets/oat.jpg"> 
                    <br > <br > 
					<router-link to="/DetailsPage1"><button class="but"> View Details </button></router-link>
				</ul>
            </div>

            <div>
                
                <ul>
					<li> Charcoal Detox Soap </li>
					<img alt="Vue sm" src="./../assets/cho.jpg"> 
                    <br > <br > 
					<router-link to="/DetailsPage2"><button class="but"> View Details </button></router-link>
				</ul>
            </div>

            <div>
                
                <ul>
					<li> Honeycomb Shea Butter Soap </li>
					<img alt="Vue sm" src="./../assets/hcs.jpg"> 
                    <br > <br > 
					<router-link to="/DetailsPage3"><button class="but"> View Details </button></router-link>
				</ul>
            </div>
        </div>
    
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>

import HeaderPage from './HeaderPage.vue'
import FooterPage from './FooterPage.vue'

export default {
    name: 'HomePage',
    components: {
       HeaderPage,
        FooterPage
        
    }
}
</script>

<style scoped>
     #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 700px;
        font-size: 10pt;
        color: black;
        text-align: left;
    }


    #footer{
        top: 410px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

</style>
