<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <section>
            <table border="0" width="100%" height="150px">
                    <tr>
                        <td width="50%" height="400px" align="center" >
                            <img alt="Vue sm" src="./../assets/ctt.jpg"> 
                        </td>

                        <td width="50%" height="400px"  align="center" >

                            <h2> Activated Charcoal and Tea Tree Oil Soap </h2>

                            <p> Activated Charcoal and Tea Tree Oil Soap combines powerful 
                            cleansing and purifying properties, making it a popular choice 
                            for skincare routines focused on detoxification and acne control. 
                            Activated charcoal has a porous structure that acts like a magnet 
                            to attract and absorb dirt, oil, and impurities from the skin's 
                            surface. Charcoal can help absorb excess oil, helping to balance 
                            oil production and reduce shine. Tea tree oil is well-known for its 
                            antibacterial and antimicrobial properties, which can help combat 
                            acne-causing bacteria on the skin.  Tea tree oil can promote healing 
                            of blemishes and minor wounds, supporting clearer and healthier skin.</p>

                            <p> Activated Charcoal and Tea Tree Oil Soap combines the purifying 
                            benefits of activated charcoal with the antibacterial properties of tea 
                            tree oil, offering a deep cleanse and refreshing experience for your skin. 
                            Whether you seek to detoxify, control acne, or maintain a clear complexion, 
                            this soap provides a potent solution with natural ingredients. Embrace the 
                            purifying power of activated charcoal and the healing properties of tea tree 
                            oil for a revitalized and healthier-looking skin.</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                        
                        </td>
                        <td align="center">
                            <router-link to ="OrderPage"> <button class="but"> Order Now </button> </router-link>
                        </td>
                    </tr>
			</table>
            </section>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    components:{
        HeaderPage, 
        FooterPage
        
    }
}
</script>
   
<style scoped>

    #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 300px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    } 
      
</style>