<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <section>
            <table border="0" width="100%" height="150px">
                    <tr>
                        <td width="50%" height="400px" align="center" >
                            <img alt="Vue sm" src="./../assets/lcs.jpg"> 
                        </td>

                        <td width="50%" height="400px"  align="center" >

                            <h2>  Lavender and Chamomile Soap </h2>

                            <p> Lavender and Chamomile Soap combines the soothing 
                            and aromatic properties of lavender and chamomile, 
                            creating a gentle and relaxing skincare experience.Lavender is
                            renowned for its calming and soothing properties, making it ideal
                            for reducing stress and promoting relaxation. Lavender oil has 
                            natural antibacterial properties that can help cleanse and purify
                            the skin, making it beneficial for acne-prone skin.</p>

                            <p> Chamomile has anti-inflammatory properties that can help soothe 
                            irritated skin and reduce redness or inflammation. It is also hydrating
                            and can help maintain the skin's moisture balance, making it suitable 
                            for dry or sensitive skin types. Lavender and Chamomile Soap provides
                            a gentle cleanse that nourishes and soothes the skin without stripping 
                            its natural oils. Regular use can help improve skin texture, leaving it 
                            soft, smooth, and refreshed. </p>

                            <p> Lavender and Chamomile Soap combines the therapeutic benefits of 
                            lavender and chamomile, offering a soothing and gentle cleanse that 
                            promotes relaxation and skin health. Whether you seek a calming skincare 
                            ritual or a natural remedy for sensitive skin concerns, this soap provides 
                            a luxurious experience with every use. Embrace the calming essence of 
                            lavender and chamomile for a rejuvenating and serene skincare routine. </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                        
                        </td>
                        <td align="center">
                            <router-link to ="OrderPage"> <button class="but"> Order Now </button> </router-link>
                        </td>
                    </tr>
			</table>
            </section>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    components:{
        HeaderPage, 
        FooterPage
        
    }
}
</script>
   
<style scoped>

    #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 300px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    } 
      
</style>