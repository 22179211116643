<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <section>
            <table border="0" width="100%" height="150px">
                    <tr>
                        <td width="50%" height="400px" align="center" >
                            <img alt="Vue sm" src="./../assets/gtm.jpg"> 
                        </td>

                        <td width="50%" height="400px"  align="center" >

                            <h2> Green Tea and Mint Soap </h2>

                            <p> Green tea and mint soap combines the refreshing qualities of 
                            mint with the antioxidant benefits of green tea, creating a 
                            unique and beneficial skincare product.  Green tea is rich in 
                            antioxidants, particularly polyphenols like catechins. These 
                            compounds help neutralize free radicals, which can damage skin 
                            cells and lead to signs of aging. By using green tea soap, you can 
                            incorporate these antioxidants directly into your skincare routine, 
                            promoting healthier and more resilient skin. Mint is known for its 
                            cooling and refreshing properties. When used in soap, mint can provide 
                            a tingling sensation that feels invigorating on the skin. This can be 
                            particularly refreshing during hot weather or after a long day.</p>

                            <p> Both green tea and mint have natural cleansing properties. Green tea 
                            helps to cleanse the skin of impurities and excess oils without stripping 
                            it of essential moisture. Mint, on the other hand, has natural antibacterial 
                            properties that can help prevent acne and other skin infections.</p>

                            <p> Overall, green tea and mint soap offers a refreshing, antioxidant-rich 
                            cleansing experience that can benefit your skin in multiple ways. 
                            Whether you're looking to enhance your skincare routine or simply enjoy the 
                            sensory experience, this combination is definitely worth trying.</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                        
                        </td>
                        <td align="center">
                            <router-link to ="OrderPage"> <button class="but"> Order Now </button> </router-link>
                        </td>
                    </tr>
			</table>
            </section>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    components:{
        HeaderPage, 
        FooterPage
        
    }
}
</script>
   
<style scoped>

    #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 300px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    } 
      
</style>