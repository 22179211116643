<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <section>
            <table border="0" width="100%" height="150px">
                    <tr>
                        <td width="50%" height="400px" align="center" >
                            <img alt="Vue sm" src="./../assets/hcs.jpg"> 
                        </td>

                        <td width="50%" height="400px"  align="center" >

                            <h2> Honeycomb Shea Butter Soap </h2>

                            <p> Honeycomb Shea Butter Soap is a luxurious skincare 
                            product celebrated for its moisturizing, nourishing,
                            and soothing properties. It is Renowned for its moisturizing
                            and healing properties, shea butter is rich in vitamins A, E,
                            and F. It deeply nourishes the skin, promoting elasticity and
                            maintaining hydration. Shea butter also helps to soothe irritation
                            and inflammation, making it suitable for sensitive skin.</p>

                            <p> Known for its natural humectant properties, honey attracts 
                            moisture to the skin, helping to keep it hydrated and supple. 
                            It also contains antioxidants and antibacterial properties, 
                            which can aid in promoting a healthy complexion and combating 
                            blemishes. It is also suitable for use throughout the year, 
                            especially during colder months when skin tends to be more 
                            prone to dryness. Honeycomb Shea Butter Soap combines the nourishing
                            benefits of shea butter and honey to provide a luxurious and 
                            moisturizing skincare experience. Whether you seek hydration, 
                            soothing relief for sensitive skin, or a natural cleanse that
                            preserves skin's moisture, this soap offers a gentle and effective 
                            solution. Embrace the richness of natural ingredients and indulge in 
                            the luxurious sensation of Honeycomb Shea Butter Soap for a healthier, 
                            radiant complexion.</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                        
                        </td>
                        <td align="center">
                            <router-link to ="OrderPage"> <button class="but"> Order Now </button> </router-link>
                        </td>
                    </tr>
			</table>
            </section>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    components:{
        HeaderPage, 
        FooterPage
        
    }
}
</script>
   
<style scoped>

    #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 300px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    } 
      
</style>