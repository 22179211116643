<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <section>
            <table border="0" width="100%" height="150px">
                    <tr>
                        <td width="50%" height="400px" align="center" >
                            <img alt="Vue sm" src="./../assets/oat.jpg"> 
                        </td>

                        <td width="50%" height="400px"  align="center" >

                            <h2> Oatmeal Lavender Dreamscape Soap </h2>

                            <p> Oatmeal Lavender Dreamscape Soap blends the soothing 
                            properties of oatmeal with the calming essence of lavender,
                            creating a luxurious bath and skincare experience. It is 
                            Renowned for its gentle exfoliating properties, oatmeal 
                            helps to slough away dead skin cells while soothing irritation 
                            and inflammation. It's ideal for sensitive skin types as it
                            moisturizes and maintains the skin's natural barrier. The soap
                            provides a gentle yet effective cleanse, leaving the skin 
                            feeling refreshed and rejuvenated, and it also has a calming 
                            scent of lavender transforms your bathing ritual into a spa-like
                            experience, helping to alleviate tension and promote a sense 
                            of well-being. So the Oatmeal Lavender Dreamscape Soap is more
                            than just a cleansing product; it's a blend of nature's finest
                            ingredients designed to pamper your skin and soothe your soul.
                            Whether you're looking to enhance your skincare routine or seeking
                            a moment of serenity, this soap promises a luxurious experience 
                            that nourishes both body and spirit.</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                        
                        </td>
                        <td align="center">
                            <router-link to ="OrderPage"> <button class="but"> Order Now </button> </router-link>
                        </td>
                    </tr>
			</table>
            </section>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    components:{
        HeaderPage, 
        FooterPage
        
    }
}
</script>
   
<style scoped>

    #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 300px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    } 
      
</style>