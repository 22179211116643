<template>
    <div> 
      <table width="100%" border="0" height="70px">
            <tr>
                <td width="15%"> <img alt="Vue sm" src="./../assets/sm.jpg"> </td>
                <td width="85%"> 
                    <h3> Milo Soap Serminar</h3>
                </td>
            </tr>
         </table>

         <table width="100%" border="0" height="40px" >
            <tr>
                <td width="20%"> <router-link to="/" class="hi"> Home </router-link></td>
                <td width="20%"> <router-link to="/SoapSales" class="hi"> Soap Sales </router-link></td> 
                <td width="20%"> <router-link to="/SoapMakingTips" class="hi"> Soap Making Tips </router-link></td> 
                <td width="20%"> <router-link to="/About" class="hi"> About Us </router-link></td> 
                <td width="20%"> <router-link to="/Contact" class="hi" > Contact Us </router-link></td>          
            </tr>
        </table>
        <router-view />
    </div>
</template>

<script>
export default{
  
}
</script>

<style scoped>
   
    img{
        height:160px;
        width:190px
    }

    .hi{
        text-decoration:none;
    }
</style>