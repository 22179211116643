<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <h2 align="center">  Soap Making Tips Tutorial </h2>
            <section>
                <table border="0" height="150px" width="100px">
                <tr>
                    <td>
                        <ul>
                            <li> Coconut Oil and Aloe Vera Soap </li>
                        </ul>  
                    </td>
                    <br > <br >
                  
                    <td>
                        <iframe width="400" height="225" src="https://www.youtube.com/embed/nDIa4G4ewQM" title="How to make Aloe Vera Soap | DIY Homemade Coconut Oil Soap | Cold Process Soap Making For Beginners" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> 
                    </td>
                </tr>

                <tr>
                    <td>
                        <ul>
                            <li> Charcoal Detox Soap </li>
                            
                        </ul>
                    </td>
                    <br > <br >
                   
                    <td>
                        <iframe  width="400" height="225" src="https://www.youtube.com/embed/rxFhvoaQM2Q" title="Making Detox Soap using Activated Charcoal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </td>
                </tr>

                <tr>
                    <td>
                        <ul>
                            <li> Honeycomb Shea Butter Soap </li>
                        </ul>
                    </td>
                   <br > <br >
                    <td>
                        <iframe width="400" height="225" src="https://www.youtube.com/embed/oN-x2d1huhA" title="How to Make Your Own Shea Butter &amp; Honey Melt &amp; Pour Soap! SUPER Easy to Make! - DIY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </td>
                </tr>
                </table>
            </section>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"
export default {
    name:'SoapMakingTipsPage',
    components:{
        HeaderPage, 
        FooterPage
        
    }
}
</script>
   
<style scoped>

#header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 500px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 1500px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .it{
        text-align: left;
    }
</style>