<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <div class="container">
                <h2>Contact Us</h2>
                <p>Thank you for your interest in contacting us. Please use one of the methods below to get in touch.</p>

                <div class="contact-methods">
                    <div>
                        <h3>Email</h3>
                        <p><a href="mailto:ilooribhabor17@gmail.com">ilooribhabor17@gmail.com</a></p>
                    </div>
                    <div>
                        <h3>Phone</h3>
                        <p><a href="tel:+2348165681839">+234 816 5681 839</a></p>
                    </div>
                    <div>
                        <h3>Address</h3>
                        <p>123 Agric bus stop, Lagos, Nigeria</p>
                    </div>
                </div>

                <form action="/submit_contact_form" method="post">
                    <label for="name">Name:</label>
                    <input type="text" id="name" name="name" required>

                    <label for="email">Email:</label>
                    <input type="email" id="email" name="email" required>

                    <label for="message">Message:</label>
                    <textarea id="message" name="message" rows="4" required></textarea>

                    <input type="submit" value="Send Message">
                </form>
            </div>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>

import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    name: "ContactPage",
    components: {
        HeaderPage,
        FooterPage
        
        
    }
}
</script>
   
<style scoped>

#header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 400px;
        font-size: 10pt;
        color: black;
        text-align:center;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }
  
    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    
    body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
        }
        .container {
            max-width: 800px;
            margin: auto;
            padding: 50px;
            background-color: #ffb6c1;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            border-radius: 5px;
            margin-top: 50px;
        }
        h2 {
            text-align: center;
            color: #333;
        }
        p {
            margin-bottom: 20px;
            color: #666;
        }
        .contact-methods {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }
        .contact-methods div {
            flex: 1;
            text-align: center;
            padding: 10px;
            background-color: #f0f0f0;
            border-radius: 5px;
            transition: background-color 0.3s ease;
        }
        .contact-methods div:hover {
            background-color: #e0e0e0;
        }
        .form {
            margin-top: 20px;
        }
        .form label {
            display: block;
            margin-bottom: 5px;
            color: #333;
        }
        form input[type="text"],
        form input[type="email"],
        form textarea {
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            font-size: 16px;
            color: #555;
        }
        form textarea {
            resize: vertical;
            min-height: 100px;
        }
        form input[type="submit"] {
            background-color: #4CAF50;
            color: white;
            border: none;
            padding: 15px 20px;
            cursor: pointer;
            border-radius: 5px;
            font-size: 16px;
            transition: background-color 0.3s ease;
        }
        form input[type="submit"]:hover {
            background-color: #45a049;
        }
        @media (max-width: 600px) {
            .contact-methods {
                flex-direction: column;
            }
            .contact-methods div {
                margin-bottom: 10px;
            }
        }

</style>