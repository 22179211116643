<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <section>
            <table border="0" width="100%" height="150px">
                    <tr>
                        <td width="50%" height="400px" align="center" >
                            <img alt="Vue sm" src="./../assets/cho.jpg"> 
                        </td>

                        <td width="50%" height="400px"  align="center" >

                            <h2> Charcoal Detox Soap </h2>

                            <p> Charcoal Detox Soap is a popular skincare product renowned
                            for its deep-cleansing and purifying properties. It is Often
                            enriched with moisturizing oils and butters such as coconut oil, 
                            shea butter, or olive oil, which help to hydrate and nourish the 
                            skin while maintaining its natural moisture balance. It helps to 
                            detoxify the skin by removing impurities and pollutants, leaving
                            behind a clean and refreshed complexion. It is Suitable for various
                            skin types, but particularly beneficial for those with oily or
                            combination skin seeking a deep cleanse. And it Often infused with
                            refreshing scents like eucalyptus, mint, or citrus, which provide
                            a pleasant aroma and a rejuvenating sensation. Charcoal Detox Soap 
                            is a versatile skincare solution that combines the purifying power 
                            of activated charcoal with nourishing oils and gentle exfoliants.
                            Whether you're looking to deep-cleanse your pores, control excess 
                            oil, or achieve a fresh and revitalized complexion, this soap offers
                            a refreshing and effective skincare experience. Incorporate it into
                            your routine to enjoy the benefits of detoxification and rejuvenation
                            for healthier-looking skin.</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                        
                        </td>
                        <td align="center">
                            <router-link to ="OrderPage"> <button class="but"> Order Now </button> </router-link>
                        </td>
                    </tr>
			</table>
            </section>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"
export default {
    components:{
        HeaderPage, 
        FooterPage
        
    }
}
</script>
   
<style scoped>

    #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 300px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    } 
      
</style>