import {createWebHistory,createRouter} from 'vue-router'

import Home from './components/HomePage.vue';
import SoapSales from './components/SoapSalesPage.vue';
import SoapMakingTips from './components/SoapMakingTipsPage.vue';
import About from './components/AboutPage.vue';
import Contact from './components/ContactPage.vue';
import DetailsPage1 from './components/DetailsPage1.vue';
import DetailsPage2 from './components/DetailsPage2.vue';
import DetailsPage3 from './components/DetailsPage3.vue';
import DetailsPage4 from './components/DetailsPage4.vue';
import DetailsPage5 from './components/DetailsPage5.vue';
import DetailsPage6 from './components/DetailsPage6.vue';
import DetailsPage7 from './components/DetailsPage7.vue';
import DetailsPage8 from './components/DetailsPage8.vue';
import DetailsPage9 from './components/DetailsPage9.vue';
import DetailsPage10 from './components/DetailsPage10.vue';
import OrderPage from './components/OrderPage.vue';

const routes=[
    {
        name:'Home',
        path:'/',
        component:Home
    },
    {
        name:'SoapSales',
        path:'/SoapSales',
        component:SoapSales
    },
    {
        name:'SoapMakingTips',
        path:'/SoapMakingTips',
        component:SoapMakingTips
    },
    {
        name: 'About',
        path:'/About',
        component:About
    },
    {
        name:'Contact',
        path:'/Contact',
        component:Contact
    }, 
    {
        name:'DetailsPage1',
        path:'/DetailsPage1',
        component:DetailsPage1
    }, 
    {
        name:'DetailsPage2',
        path:'/DetailsPage2',
        component:DetailsPage2
    },  
    {
        name:'DetailsPage3',
        path:'/DetailsPage3',
        component:DetailsPage3
    }, 
    {
        name:'DetailsPage4',
        path:'/DetailsPage4',
        component:DetailsPage4
    }, 
    {
        name:'DetailsPage5',
        path:'/DetailsPage5',
        component:DetailsPage5
    }, 
    {
        name:'DetailsPage6',
        path:'/DetailsPage6',
        component:DetailsPage6
    }, 
    {
        name:'DetailsPage7',
        path:'/DetailsPage7',
        component:DetailsPage7
    }, 
    {   name:'DetailsPage8',
        path:'/DetailsPage8',
        component:DetailsPage8
    }, 
    {
        name:'DetailsPage9',
        path:'/DetailsPage9',
        component:DetailsPage9
    }, 
    {
        name:'DetailsPage10',
        path:'/DetailsPage10',
        component:DetailsPage10
    }, 
    {
        name:'OrderPage',
        path:'/OrderPage',
        component:OrderPage
    }, 
];


const router=createRouter({
    history:createWebHistory(),
    routes
})

export default router;