<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <div class="container">
                <h2>Soap Order Form</h2>
                <form action="#" method="POST">
                    <div class="form-group">
                        <label for="fullname">Full Name:</label>
                        <input type="text" id="fullname" name="fullname" required>
                    </div>
                    <div class="form-group">
                        <label for="email">Email Address:</label>
                        <input type="email" id="email" name="email" required>
                    </div>
                    <div class="form-group">
                        <label for="phone">Phone Number:</label>
                        <input type="text" id="phone" name="phone" required>
                    </div>
                    <div class="form-group">
                        <label for="address1">Address Line 1:</label>
                        <input type="text" id="address1" name="address1" required>
                    </div>
                    <div class="form-group">
                        <label for="address2">Address Line 2:</label>
                        <input type="text" id="address2" name="address2">
                    </div>
                    <div class="form-group">
                        <label for="city">City:</label>
                        <input type="text" id="city" name="city" required>
                    </div>
                    <div class="form-group">
                        <label for="state">State/Province:</label>
                        <input type="text" id="state" name="state" required>
                    </div>
                    <div class="form-group">
                        <label for="zipcode">Postal/ZIP Code:</label>
                        <input type="text" id="zipcode" name="zipcode" required>
                    </div>
                    <div class="form-group">
                        <label for="country">Country:</label>
                        <input type="text" id="country" name="country" required>
                    </div>
                    <div class="form-group">
                        <label for="soaptype">Soap Type/Variant:</label>
                        <input type="text" id="soaptype" name="soaptype" required>
                    </div>
                    <div class="form-group">
                        <label for="quantity">Quantity:</label>
                        <input type="number" id="quantity" name="quantity" required>
                    </div>
                    <div class="form-group">
                        <label for="notes">Additional Requests or Notes:</label>
                        <textarea id="notes" name="notes"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="payment">Payment Method:</label>
                        <input type="text" id="payment" name="payment" required>
                    </div>
                    <button type="submit" class="submit-btn">Submit Order</button>
                </form>
            </div>
           
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>

import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    name: "OrderPage",
    components: {
        HeaderPage,
        FooterPage
        
        
    }
}
</script>
   
<style scoped>

#header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 400px;
        font-size: 10pt;
        color: black;
        text-align:center;
   }

    #footer{
        top: 900px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }
  
    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    body {
            font-family: Arial, sans-serif;
            background-color: #f2f2f2;
            padding: 20px;
        }
        .container {
            max-width: 600px;
            margin: auto;
            background: #fff;
            padding: 20px;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
        }
        .form-group {
            margin-bottom: 20px;
        }
        .form-group label {
            font-weight: bold;
            display: block;
            margin-bottom: 5px;
        }
        .form-group input[type="text"],
        .form-group input[type="email"],
        .form-group textarea {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 3px;
            font-size: 16px;
        }
        .form-group textarea {
            resize: vertical;
            height: 100px;
        }
        .submit-btn {
            background-color: #4CAF50;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 3px;
            cursor: pointer;
            font-size: 16px;
        }
        .submit-btn:hover {
            background-color: #45a049;
        }

</style>