<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <h2>  About Us Page </h2>
            <p> "It all started with a gift basket full of harsh, chemical-laden soaps. 
                We craved a more natural and gentle experience, so we began experimenting 
                with homemade soap recipes. What began as a kitchen hobby has blossomed
                into a passion for creating luxurious, all-natural soaps that are kind
                 to your skin and the environment." </p>

            <p> "At Milo Soap Making Online Seminar, we believe that self-care shouldn't
                 come at the cost of your health or the planet. That's why we use only the
                 finest organic ingredients and sustainable practices to create soap that 
                 nourishes your skin and respects the earth." </p>

            <p> "We handcraft every bar of soap in small batches, ensuring the highest
                quality and attention to detail. We pour our hearts (and sometimes a little
                too much essential oil!) into every creation, hoping to bring a touch of
                luxury and joy to your daily routine." </p>
                
                <img alt="Vue sm" src="./../assets/sm.jpg">
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
    
</template>

<script>

import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    name: 'AboutPage',
    components: {
        HeaderPage,
        FooterPage
        
    }
}
</script>
   
<style scoped>
   
   #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 200px;
        font-size: 10pt;
        color: black;
    }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }
</style>