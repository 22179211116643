<template>
    <div> 
        <table width="100%" border="0">
            <tr>
                <td> Soap Making Seminar. Copyright and &copy; 2024
                    
                    Home | Soap Recipes | Soap Making Tips | About Us | Contact Us
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    components:{

    }

}
</script>

<style scoped>
   
</style>