<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <h2 align="center">  Soap Sales </h2>
            <section>
                <table border="0" height="150px" width="100px">
                <h2 class="it"> Here Are Some Home Made Soap For Sales </h2>
                <tr>
                    <td>
                        <ul>
                            <li> Coconut Oil and Aloe Vera Soap </li>
                            <img alt="Vue sm" src="./../assets/oco.jpg">  
                            <br > <br > 
                            <router-link to="/DetailsPage4"> <button class="but" > View  Details </button></router-link> 
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>   Oatmeal and Honey Soap </li>
                            <img alt="Vue sm" src="./../assets/oah.jpg"> 
                            <br > <br > 
                            <router-link to ="/DetailsPage5"><button class="but"> View Details </button></router-link>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>  Lavender and Chamomile Soap </li>
                            <img alt="Vue sm" src="./../assets/lcs.jpg"> 
                            <br > <br > 
                            <router-link to ="/DetailsPage6"><button class="but"> View Details </button></router-link>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li> Activated Charcoal and Tea Tree Oil Soap  </li>
                            <img alt="Vue sm" src="./../assets/ctt.jpg"> 
                            <br > <br > 
                            <router-link to ="/DetailsPage7"><button class="but"> View Details </button></router-link>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ul>
                            <li> Citrus and Mint Soap </li>
                            <img alt="Vue sm" src="./../assets/cm.jpg"> 
                            <br > <br > 
                            <router-link to ="/DetailsPage8"><button class="but"> View Details </button></router-link>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li> Honey and Oatmeal Goat Milk Soap </li>
                            <img alt="Vue sm" src="./../assets/hog.jpg"> 
                            <br > <br > 
                            <router-link to ="/DetailsPage9"><button class="but"> View Details </button></router-link>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li> Green Tea and Mint Soap </li>
                            <img alt="Vue sm" src="./../assets/gtm.jpg"> 
                            <br > <br > 
                            <router-link to ="/DetailsPage10"><button class="but"> View Details </button></router-link> 
                        </ul>
                    </td>
                </tr>	
                </table>
            </section>
            
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    name:"SoapSalesPage",
    components:{
        HeaderPage,
        FooterPage
        
    }
}
</script>
   
<style scoped>

#header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 550px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .it{
        text-align: left;
    }

</style>