<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <section>
            <table border="0" width="100%" height="150px">
                    <tr>
                        <td width="50%" height="400px" align="center" >
                            <img alt="Vue sm" src="./../assets/oco.jpg"> 
                        </td>

                        <td width="50%" height="400px"  align="center" >

                            <h2> Coconut Oil and Aloe Vera Soap </h2>

                            <p> Coconut Oil and Aloe Vera Soap combines two powerhouse
                            ingredients known for their nourishing and soothing properties,
                            making it an excellent choice for skincare enthusiasts seeking 
                            a gentle and effective cleansing experience. Coconut oil is rich
                            in fatty acids that help hydrate and nourish the skin, leaving it 
                            soft and supple. It contains lauric acid, which has antibacterial 
                            properties that can help protect the skin from harmful bacteria.
                            Coconut oil is also known for its antioxidant properties, which can 
                            help combat free radicals and reduce signs of aging.</p>

                            <p>  Aloe vera is renowned for its soothing and calming effects on 
                            the skin, making it ideal for sensitive or irritated skin. And it
                            can promote healing of minor cuts, burns, and skin irritations due
                            to its anti-inflammatory and wound-healing properties. Coconut Oil
                            and Aloe Vera Soap offers a harmonious blend of moisturizing coconut 
                            oil and soothing aloe vera, providing a gentle yet effective cleansing 
                            experience. Whether you seek hydration, soothing relief for sensitive 
                            skin, or simply a refreshing and natural alternative for your skincare 
                            routine, this soap promises to nourish and pamper your skin with every 
                            use. Embrace the natural goodness of coconut oil and aloe vera for a 
                            healthier, glowing complexion.</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                        
                        </td>
                        <td align="center">
                            <router-link to ="OrderPage"> <button class="but"> Order Now </button> </router-link>
                        </td>
                    </tr>
			</table>
            </section>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    components:{
        HeaderPage, 
        FooterPage
        
    }
}
</script>
   
<style scoped>

    #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 300px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    } 
      
</style>