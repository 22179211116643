<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <section>
            <table border="0" width="100%" height="150px">
                    <tr>
                        <td width="50%" height="400px" align="center" >
                            <img alt="Vue sm" src="./../assets/oah.jpg"> 
                        </td>

                        <td width="50%" height="400px"  align="center" >

                            <h2>  Oatmeal and Honey Soap </h2>

                            <p>Oatmeal and Honey Soap is a popular choice in natural skincare,
                            renowned for its gentle exfoliating properties and moisturizing 
                            benefits. Oatmeal contains anti-inflammatory properties that can 
                            help calm irritated skin and alleviate itching or redness.It's 
                            naturally moisturizing, helping to lock in moisture and keep the 
                            skin hydrated.  Honey is a humectant, which means it attracts and
                            retains moisture, making it ideal for dry or dehydrated skin.
                            Honey has natural antibacterial properties that can help cleanse
                            and clarify the skin, making it beneficial for acne-prone skin.</p>

                            <p> Oatmeal and Honey Soap provides a mild and gentle cleanse, suitable 
                            for all skin types, including sensitive skin. The combination of oatmeal
                            and honey nourishes the skin, leaving it feeling soft, smooth, and
                            refreshed. Oatmeal and Honey Soap combines the exfoliating power of oatmeal
                            with the moisturizing and healing properties of honey, offering a gentle 
                            yet effective solution for healthy-looking skin. Whether you seek gentle 
                            exfoliation, soothing hydration, or a natural cleanser that respects your 
                            skin's balance, this soap provides a nourishing experience with every use.
                            Embrace the goodness of oatmeal and honey for a rejuvenated and radiant
                            complexion.</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                        
                        </td>
                        <td align="center">
                            <router-link to ="OrderPage"> <button class="but"> Order Now </button> </router-link>
                        </td>
                    </tr>
			</table>
            </section>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    components:{
        HeaderPage, 
        FooterPage
        
    }
}
</script>
   
<style scoped>

    #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 300px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    } 
      
</style>