<template>
    <div> 
        <div id="header">
            <HeaderPage />
        </div>

        <div id="content">
            <section>
            <table border="0" width="100%" height="150px">
                    <tr>
                        <td width="50%" height="400px" align="center" >
                            <img alt="Vue sm" src="./../assets/hog.jpg"> 
                        </td>

                        <td width="50%" height="400px"  align="center" >

                            <h2> Honey and Oatmeal Goat Milk Soap </h2>

                            <p> Honey and Oatmeal Goat Milk Soap combines the nourishing 
                            properties of honey, oatmeal, and goat milk, offering a luxurious 
                            and gentle cleansing experience for your skin. Goat milk is rich in 
                            fatty acids and triglycerides, which are known for their moisturizing 
                            and hydrating properties. It helps to keep the skin soft and supple.
                            It contains vitamins A, D, E, and several B vitamins, as well as minerals 
                            like selenium, which are beneficial for skin health. Honey is a natural 
                            humectant, meaning it attracts moisture to the skin and helps retain it, 
                            keeping the skin hydrated.  It contains antioxidants that help protect 
                            the skin from environmental damage, promoting a youthful appearance.</p>

                            <p> Honey and Oatmeal Goat Milk Soap offers a luxurious blend of honey, 
                            oatmeal, and goat milk, providing gentle cleansing and nourishment for 
                            your skin. Whether you seek hydration, soothing relief for sensitive skin, 
                            or simply a natural and effective cleanser, this soap delivers a pampering 
                            experience with every use. Embrace the richness of goat milk, honey, and 
                            oatmeal for a rejuvenated and healthier-looking complexion.</p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                        
                        </td>
                        <td align="center">
                            <router-link to ="OrderPage"> <button class="but"> Order Now </button> </router-link>
                        </td>
                    </tr>
			</table>
            </section>
        </div>
       
        <div id="footer">
            <FooterPage />
        </div>
    </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue"
import FooterPage from "../components/FooterPage.vue"

export default {
    components:{
        HeaderPage, 
        FooterPage
        
    }
}
</script>
   
<style scoped>

    #header{
        top: 0px;
        left: 0px;
        width: 100%;
        height: 260px;
        background-color: pink;
        position: absolute;
    }

    #content{
        top: 260px;
        left: 0px;
        width: 100%;
        height: 300px;
        font-size: 10pt;
        color: black;
        text-align: left;
   }

    #footer{
        top: 300px;
        left: 0px;
        width: 100%;
        height: 100px;
        font-size:10pt;
        background-color: grey;
        position: relative;
    }

    .but {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    } 
      
</style>